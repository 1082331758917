@import '@americastestkitchen/mise/mise-styles/main.scss';

.outer {
  height: 100%;
  z-index: 10;

  @media screen and (min-width: $miseBreakpointLg) {
    position: relative;
  }
}

.backgroundFill {
  display: none;

  @media screen and (min-width: $miseBreakpointMd) {
    background-color: rgba(0,0,0,0.5);
    display: block;
    left: 0;
    position: absolute;
    top: 50px;
    width: 100vw;
    height: 100vh;
  }
}

.button {
  color: $miseColorNeutralLightTint;
  height: 20px;
  width: 20px;

  &:hover {
  cursor: pointer;
    color: $miseColorPrimaryMidA11y;
    outline-color: $miseColorNeutralLightTint;
  }
}

.wrapper {
  position: absolute;
  max-width: 480px;
  left: 0;
  width: 100%;

  background-color: $miseColorPrimaryDarkShade;

  :global(.main-nav) & {
    height: calc(100vh - 91px);
    top: 81px;
  }

  :global(.global-header-search-bar) {
    max-width: 413px;
  }
  

  @media screen and (min-width: $miseBreakpointLg) {
    width: 480px;
  }
}

.hideDrawer {
  display: none;
}

.ctaWrapper {
  background-color: $miseColorPrimaryLightTint;
  padding: $miseSpacingx3 $miseSpacingx4 18px;
}

.ctaList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.ctaLink {
  font-size: 12px;
  font-family: 'Proxima-Nova', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  background-color: $miseColorNeutralLightTint;
  box-shadow: 2px 2px 4px 1px rgba(0, 0, 0, 0.02);
  border-radius: 8px;
  align-items: center;
  display: flex;
  justify-content: center;
  height: 40px;
  max-width: calc(50% - 10px);
  text-align: center;
  width: calc(50% - 10px);

  &:first-child {
    margin-bottom: $miseSpacingx1;
  }

  &:hover {
    color: $miseColorPrimaryMidBase;
    outline-color: $miseColorNeutralDarkBase;
  }
}

.primaryLinkText {
  // @include mise.setFont(proximaNova, sm, bold, normal, caps);
  font-size: 12px;
  letter-spacing: 1.2px
}

.secondaryLinkText {
  // @include mise.setFont(proximaNova, sm, bold, normal);
  font-size: 12px;
}

.brandListWrapper {
  // background-color: mise.color(primary, dark, shade);
  // padding: mise.spacing(x2) mise.spacing(x4) 48px;
  background-color: $miseColorPrimaryDarkShade;
  padding: $miseSpacingx2 $miseSpacingx4 48px;
}

.brandList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.brandListItem {
  // @include mise.setFont(proximaNova, sm, bold, normal, caps);
  align-items: center;
  display: flex;
  font-size: 12px;
  font-family: 'Proxima-Nova', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  justify-content: center;
  letter-spacing: 1.2px;

  border-bottom: 0.5px solid $miseColorNeutralDarkTint;
  color: $miseColorNeutralLightTint;
  max-width: calc(50% - 5px);
  padding: $miseSpacingx3 0;
  text-align: center;
  width: calc(50% - 10px);
}
