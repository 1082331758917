$breakpoints: (
  sm: 480px, //30rem, // 480px
  md: 768px, //48rem, // 768px
  lg: 1024px, //64rem, // 1024px
  xl: 1200px, //75rem, // 1200px
);

@function breakpoint($breakpoint) {
  @return map-get($breakpoints, $breakpoint);
}

// Exports
// This allows variables to be used with ./src/components/tokens/Breakpoints/Breakpoints.ts
:export {
  @each $breakpoint, $value in $breakpoints {
    breakpoints-#{$breakpoint}: $value;
  }
}
