@use "./_tokens/spacing.scss";
@use "./_tokens/breakpoints.scss";

// Functional Classes
// !!! FOR USE IN ESPRESSO ONLY !!!

$breakpoints: sm md lg xl;
$sizes: x1 x2 x3 x4 x6 x8 x10 x12 x24;

@mixin sizes {
  @each $size in $sizes {
    &--x0 {
      margin-top: 0;
    }

    &--#{$size} {
      margin-top: spacing.spacing(#{$size});
    }
  }
}

.mise {
  &-spacing {
    @include sizes();

    &--x0 {
      margin-top: 0;
    }

    @each $breakpoint in $breakpoints {
      &--bp-#{$breakpoint} {
        @media only screen and (min-width: breakpoints.breakpoint(#{$breakpoint})) {
          @include sizes();

        }
      }
    }
  }
}