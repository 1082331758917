@import '@americastestkitchen/mise/mise-styles/main.scss';

.playIconWrapper {
  height: 16px;
  margin-right: $miseSpacingx1;
  width: 16px;

  @media screen and (min-width: $miseBreakpointLg) {
    height: 20px;
    width: 20px;
  }

  :hover > & path {
    fill: $miseColorPrimaryMidBase;
  }
}

