@import '@americastestkitchen/mise/mise-styles/main.scss';

$brand-nav-height: 36px;
$link-color-subdued: #C8C5C4;

.brand_nav {
  display: none;
  width: 100%;

  @media screen and (min-width: $miseBreakpointMd) {
    display: flex;
  }
}

.button {
  align-content: stretch;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  font-size: 12px;
  font-family: 'Proxima-Nova', sans-serif;
  font-weight: bold;
  gap: 5px;
  padding: 0;

  &:not(.inverse) {
    color: white;
    &:hover,
    &:focus {
      color: $link-color-subdued;
    }
  }

  &.inverse {
    color: $link-color-subdued;
    &:hover,
    &:focus {
      color: white;
    }
  }

  &:hover {
    svg path {
      fill: $miseColorPrimaryMidShade;
      stroke: $miseColorPrimaryMidShade;
    }
  }
}

.button,
.link {
  align-items: center;
  display: flex;
  font-size: 12px;
  letter-spacing: 0.1em;
  line-height: 1.15;
  text-transform: uppercase;
  text-align: center;
  transition: color 0.2s ease-in-out;

  :global(.typography) {
    color: $miseColorNeutralLightTint;
    font-size: 12px !important;
  }

  &:focus {
    outline: 2px dotted #fff !important;
  }
}

.button_icon {
  height: 5px;
  width: 8px;
  transition: transform 0.4s ease-in-out;
}

.link_list_nav {
  display: block;
  position: absolute;
  top: 100%;

  &.left {
    left: 0;
    width: 239px;
  }

  &.right {
    right: 0;
    width: 196px;
  }
}

.list {
  display: none;
}

.ribbon_icon {
  color: $miseColorPrimaryMidBase;
  height: 27px;
  width: 13px;
  transform: translate3d(-3px, -5px, 0);
}

.user, .wrapper {
  > div {
    /* for disclosure */
    display: flex;
  }
}

.user {
  display: flex;
  flex-shrink: 0;
  margin-left: auto;
  gap: 12px;
}

.wrapper {
  background-color: $miseColorPrimaryDarkShade;
  height: $brand-nav-height;
  display: flex;
  padding: 0 18px 0 16px;
  position: relative;
}

@media screen and (min-width: $miseBreakpointXl){
  .link {
    color: $link-color-subdued;
    height: 100%;
    position: relative;

    &::before {
      background-color: transparent;
      content: "";
      height: 3px;
      left: 50%;
      position: absolute;
      top: 0;
      transform: translateX(-50%);
      transition: background-color 0.4s ease-in-out;
      width: 40px;
    }

    &:hover,
    &:focus,
    .active & {
      color: white !important;
      &::before {
        background-color: $miseColorPrimaryMidBase;
      }
    }
  }

  .link_list_nav.left {
    display: none;
  }

  .list {
    display: flex;
    align-items: stretch;
    gap: 20px;
    padding: 0;
  }

  .brand_nav_mobile.active,
  .mobile_nav_toggle {
    display: none;
  }

  .user {
    gap: 20px;
  }
}

.hideMobile {
  @media screen and (max-width: $miseBreakpointMd){
    display: none;
  }
}

.hideTablet {
  @media screen and (min-width: $miseBreakpointMd){
    display: none;
  }
}
