@import '@americastestkitchen/mise/mise-styles/main.scss';

$borderStyle: solid 1px $miseColorNeutralDarkTint;
$darkModeLinkActive: linear-gradient(to top, transparent 3px, #6ba6aa 3px, #6ba6aa 5px, transparent 5px);

.wrapper {
  background-color: #191919;
  display: none;
  position: relative;
}

.nav {
  display: flex;
  justify-content: space-between;
  max-width: 1136px;
  padding: $miseSpacingx3 0;
  width: 1136px;
}

.links {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.link {
  align-items: center;
  display: flex;
  color: white;
  font-size: 20px;
  font-family: 'Proxima-Nova', sans-serif;
  font-weight: bold;

  &:hover {
    background-image: $darkModeLinkActive;
  }
}

.linkActive {
  background-image: $darkModeLinkActive;
  outline-color: $miseColorNeutralLightTint;
}

.subLinksWrapper {
  align-items: center;
  display: flex;
}

.subLinksList {
  display: flex;
}

.subLink {
  margin-right: 20px;

  :global(.typography) {
    color: #97928f;
  }

  &:last-child {
    margin-right: none;
  }

  &:hover {
    :global(.typography) {
      color: $miseColorNeutralLightTint;
    }
  }

  &:focus {
    outline-color: $miseColorNeutralLightTint;
  }
}

.subLinkActive {
  color: $miseColorNeutralLightTint;
}

.megaMenuTriggerWrapper {
  display: flex;
}

.megaMenuTrigger {
  display: none;
}

.megaMenuWrapper {
  position: absolute;
  left: 0;
  top: calc(100% + 1px);
  width: 100%;
  z-index: 2000;
}

.chevronWrapper {
  width: 12px;

  svg path {
    stroke: $miseColorNeutralLightTint;
  }
}

.hideMegaMenuContent {
  display: none;
}

.playMega {
  background-color: #191919;
  padding: $miseSpacingx6 0;
  position: absolute;
  top: calc(100% + 1px);
  left: 0;
  width: 100%;
  z-index: 10;

  :global(.highlight-cta) {
    :global(.typography) {
      color: $miseColorNeutralLightTint;
    }

    &:focus,
    &:hover {
      :global(.typography) {
        color: $miseColorNeutralDarkShade;
      }
    }
  }
}

.playMegaInner {
  color: $miseColorNeutralLightTint;
  max-width: 1136px;
  margin: 0 auto;
}

.playMegaItemHeader {
  &:global(.typography) {
    color: $miseColorNeutralLightTint;
  }
}

.playMegaItems {
  display: flex;
  justify-content: space-between;
  margin-bottom: 44px;
}

.playMegaItem {
  display: flex;

  [data-testid="underline-link"] {
    :global(.typography) {
      color: $miseColorNeutralLightTint !important;
    }
  }
}

.playMegaImage {
  height: 80px;
  margin-right: $miseSpacingx2;
  width: 80px;
}

@media screen and (min-width: $miseBreakpointMd) {
  .wrapper {
    border-bottom: $borderStyle;
    border-top: $borderStyle;
    display: flex;
    justify-content: center;
    padding: 0 $miseSpacingx4;
    width: 100%;
  }

  .subLinksList {
    margin-left: $miseSpacingx2;
  }
}

@media screen and (min-width: $miseBreakpointLg) {
  .subLinksList {
    margin-left: 0;
  }

  .megaMenuTrigger {
    display: block;
    margin-left: $miseSpacingx1;
    margin-right: 20px;
    outline-color: $miseColorNeutralLightTint;

    &:focus,
    &:active {
      outline-color: $miseColorNeutralLightTint;
    }
  }
}

