@import '@americastestkitchen/mise/mise-styles/main.scss';

.wrapper {
  display: none;

  @media screen and (min-width: $miseBreakpointMd) {
    display: block;
  }

  :global(.text-link) {
    font-size: 12px;

    @media screen and (min-width: $miseBreakpointLg) {
      display: flex;
      &:nth-child(2) {
        margin-left: $miseSpacingx4;
      }
    }

    &:focus {
      outline-color: $miseColorNeutralLightTint;
    }
  }
}

.links {
  :global(.highlight-link) :global(.typography) {
    font-size: 12px;
    letter-spacing: 1.4px;
    text-transform: uppercase;
  }

  @media screen and (min-width: $miseBreakpointMd) {
    display: flex;
  }

  :global(.highlight-cta) {
    :global(.typography) {
      color: $miseColorNeutralLightTint !important;
      font-size: 12px !important;
    }

    &:active,
    &:hover,
    &:focus {
      outline-color: $miseColorNeutralLightTint !important;

      :global(.typography) {
        color: $miseColorNeutralDarkShade !important;
      }
    }
  }
}

.secondaryLink {
  :global(.typography) {
    color: $miseColorNeutralLightTint;
    font-size: 12px !important;
  }

  &:hover {
    :global(.typography) {
      color: $miseColorPrimaryMidA11y;
    }
  }
}

.linkSet {
  &:nth-child(2) {
    margin-left: $miseSpacingx2;
  }
}
