@import '@americastestkitchen/mise/mise-styles/main.scss';

.form {
  position: relative;
  width: 100%;

  overflow: hidden;

  @media screen and (min-width: $miseBreakpointLg) {
    overflow: unset;
  }
}

.searchBar {
  height: 30px;
  position: relative;
  width: 100%;
}

.spyglassAndLabel {
  align-items: center;
  display: flex;
  position: absolute;
  left: 10px;
  top: 6px;
}

.spyglassIcon {
  height: 20px;
  width: 20px;
  margin-right: $miseSpacingx2;

  &:active,
  &:focus {
    outline-color: $miseColorNeutralLightTint;
  }

  &:hover {
    svg path {
      fill: $miseColorNeutralDarkBase;
    }
  }
}

.mobileLabel {
  display: block;

  @media screen and (min-width: $miseBreakpointMd) {
    display: none;
  }
}

.standardLabel {
  display: none;

  @media screen and (min-width: $miseBreakpointMd) {
    display: block;
  }
}

.label {
  font-size: 16px;
  font-style: italic;
  font-family: 'Proxima-Nova', sans-serif;
  color: $miseColorNeutralLightTint;
  line-height: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.hideLabel {
  display: none;
}

.input {
  font-size: 16px;
  font-family: 'Proxima-Nova', sans-serif;
  color: $miseColorNeutralLightTint;
  border: solid 1px $miseColorNeutralDarkTint;
  height: 100%;
  line-height: 1;
  padding-left: 36px;
  width: 100%;
}
