@import '@americastestkitchen/mise/mise-styles/main.scss';

$border-color: $miseColorNeutralDarkTint;
$baseColor: $miseColorNeutralDarkShade;
$interactColor: $miseColorPrimaryMidBase;

.link_list {
  background: #fff;
  border: 0.5px solid $border-color;
  text-align: center;
  position: relative;
  z-index: 20;
}

.item {
  display: flex;
  font-size: 12px;
  height: 38px;
  justify-content: center;
  letter-spacing: 0.1em;
  position: relative;
  text-transform: uppercase;
}

.link {
  align-items: center;
  color: $baseColor;
  display: flex;
  font-size: 12px;
  flex: 1;
  justify-content: center;
  padding: 0 $miseSpacingx4;

  &_active {
    :global(.typography) {
      color: $interactColor;
      outline-color: $baseColor;
    }
  }

  &:hover {
    :global(.typography) {
      color: $interactColor;
      outline-color: $baseColor;
    }
  }
}

.leftAlign {
  justify-content: flex-start;
}

.list {
  & * + * {
    border-top: 1px solid $border-color;
  }
}

.magazineAdLink {
  border-top: 0;
  height: 50px;
  max-width: 70px;
  overflow: hidden;
  position: absolute;
  right: 16px;
  top: -13px;
  width: 70px;
}

.playIconWrapper {
  display: none;

  @media screen and (min-width: $miseBreakpointLg) {
    display: block;
    height: 20px;
    margin-right: $miseSpacingx1;
    width: 20px;
  }
}

