@import '@americastestkitchen/mise/mise-styles/main.scss';

.wrapper {
  align-items: center;
  display: flex;
  padding: $miseSpacingx2 $miseSpacingx4;

  @media screen and (min-width: $miseBreakpointMd) {
    padding: $miseSpacingx4;

    :global(.global-header-hamburger) {
      display: none;
    }
  }

  background-color: #191919;
}

.inner {
  align-items: center;
  display: flex;
  width: 100%;

  :global(.global-search-form) {
    height: 30px;
  }

  @media screen and (min-width: $miseBreakpointMd) {
    justify-content: space-between;

    :global(.global-search-form) {
      height: 40px;
      max-width: 260px;

      svg {
        height: 20px;
        width: 20px;
      }
    }

    :global(.search-bar-label-wrapper) {
      top: 10px;
    }

    :global(.global-header-search-bar-label) {
      font-size: 16px;
      font-style: italic;
      font-family: 'Proxima-Nova', sans-serif;
      color: $miseColorNeutralLightTint;
      line-height: 1;
    }

    :global(.global-header-search-input) {
      font-size: 16px;
      font-family: 'Proxima-Nova', sans-serif;
      color: $miseColorNeutralLightTint;
      height: 40px;
      line-height: 1;
    }
  }


  @media screen and (min-width: $miseBreakpointLg) {
    margin: 0 auto;
    max-width: 1136px;

    :global(.global-search-form) {
      max-width: 506px;

    }
  }
}

.logoCtas {
  margin: 0 $miseSpacingx2;

  @media screen and (min-width: $miseBreakpointLg) {
    align-items: center;
    display: flex;
    margin: 0;
  }
}

.logo {
  display: flex;
  height: fit-content;

  a {
    align-items: center;
    display: flex;
    height: 30px;
    width: 82px;
  }

  @media screen and (min-width: $miseBreakpointMd) {
    margin-right: $miseSpacingx3;
    a {
      height: 50px;
      width: 136px;

     color: $miseColorNeutralLightTint;
    }
  }

  @media screen and (min-width: $miseBreakpointLg) {
    margin-right: $miseSpacingx6;
  }
}

.logoLink {
  background-color: $miseColorNeutralLightTint;
  
  svg {
    width: 100%;
  }
}

.playLogo {
  margin-left: 6px;
  max-width: 90px;
}
