// Import web fonts from TypeKit
@import url('https://use.typekit.net/nio2hfe.css');

$fontFamilies: (
  merriweather: (
    family: #{Merriweather, serif},
    lineHeight: (
      base: 1.625,
    ),
    size: (
      base: 1.6rem, //1rem,
    ),
    style: (
      normal: normal,
      italic: italic,
    ),
    weight: (
      normal: 400,
      bold: 700,
    ),
  ),
  proximaNova: (
    family: #{"Proxima-Nova", sans-serif},
    lineHeight: (
      base: 1.625,
    ),
    size: (
      base: 1.6rem, //1rem,
    ),
    style: (
      normal: normal,
      italic: italic,
    ),
    weight: (
      normal: 400,
      bold: 700,
    ),
  ),
  moret: (
    family: #{Moret, serif},
    lineHeight: (
      base: 1.125,
    ),
    size: (
      base: 3.6rem, //2.25rem,
    ),
    style: (
      normal: normal,
    ),
    weight: (
      bold: 700,
    ),
  ),
);

@function fontFamily($keys...) {
  $value: $fontFamilies;
  @each $key in $keys {
    $value: map-get($value, $key);
  }
  @return $value;
}

$resetTypography: (
  family: fontFamily(merriweather, family),
  size: 16px,
  letterSpacing: -0.02em,
  lineHeight: 1.625,
  font-weight: fontFamily(merriweather, weight, normal),
);

@function resetTypography($prop) {
  @return map-get($resetTypography, $prop);
}

@mixin resetTypography {
  font-family: resetTypography(family);
  font-size: resetTypography(size);
  font-weight: resetTypography(weight);
  letter-spacing: resetTypography(letterSpacing);
  line-height: resetTypography(lineHeight);
}

$typography: (
  text: (
    primary: (
      family: fontFamily(merriweather, family),
      letterSpacing: -0.02em,
      lineHeight: (
        sm: 1.625,
        md: 1.625,
        lg: 1.625,
        xl: 1.625,
      ),
      size: (
        sm: 1.4rem, //0.875rem,
        md: 1.6rem, //1rem,
        lg: 1.8rem, //1.125rem,
        xl: 2.4rem, //1.5rem,
      ),
      style: (
        normal: normal,
        italic: italic,
      ),
      weight: (
        normal: 400,
        bold: 700,
      ),
    ),
    secondary: (
      family: fontFamily(proximaNova, family),
      letterSpacing: -0.02em,
      lineHeight: (
        sm: 1.625,
        md: 1.625,
        lg: 1.625,
        xl: 1.625,
        xxl: 2.25,
        xxxl: 2.265,
      ),
      size: (
        sm: 1.4rem, //0.875rem,
        md: 1.6rem, //1rem,
        lg: 1.8rem, //1.125rem,
        xl: 2.4rem, //1.5rem,
        xxl: 3.2rem, //2rem,
        xxxl: 3.68rem, //2.3rem,
      ),
      style: (
        normal: normal,
        italic: italic,
      ),
      weight: (
        normal: 400,
        bold: 700,
      ),
    ),
  ),
  display: (
    primary: (
      family: fontFamily(moret, family),
      letterSpacing: 0,
      lineHeight: (
        sm: 1.125,
        md: 1.125,
        lg: 1.125,
      ),
      size: (
        sm: 3.2rem, //2rem,
        md: 3.6rem, //2.25rem,
        lg: 4.8rem, //3rem,
      ),
      style: (
        normal: normal,
      ),
      weight: (
        bold: 700,
      ),
    ),
    secondary: (
      family: fontFamily(proximaNova, family),
      letterSpacing: 1px,
      lineHeight: (
        xs: 1.625,  
        sm: 1.625,
        md: 1.625,
        lg: 1.625,
        xl: 1.625,
      ),
      size: (
        xs: 1rem, //0.625rem,
        sm: 1.4rem, //0.875rem,
        md: 1.6rem, //1rem,
        lg: 1.8rem, //1.125rem,
        xl: 2.4rem, //1.5rem,
      ),
      style: (
        normal: normal,
      ),
      weight: (
        normal: 400,
        bold: 700,
      ),
      uppercase: true,
    ),
  ),
);

@function typography($keys...) {
  $value: $typography;
  @each $key in $keys {
    $value: map-get($value, $key);
  }
  @return $value;
}

@mixin truncate($width: 100%) {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: $width;
}

@mixin truncateLineClamp($lines: 3) {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
}

@mixin font($category, $name, $size: md, $weight: normal, $style: normal) {
  font-family: typography($category, $name, family);
  font-size: typography($category, $name, size, $size);
  letter-spacing: typography($category, $name, letterSpacing);
  line-height: typography($category, $name, lineHeight, $size);
  @if typography($category, $name, weight, $weight) {
    font-weight: typography($category, $name, weight, $weight);
  } @else if typography($category, $name, weight, normal) {
    font-weight: typography($category, $name, weight, normal);
  } @else {
    font-weight: typography($category, $name, weight, bold);
  }
  @if $style == italic and typography($category, $name, style, italic) {
    font-style: italic;
  }
  @if typography($category, $name, uppercase) {
    text-transform: uppercase;
  }
  @if typography($category, $name, letterSpacing) {
    letter-spacing: typography($category, $name, letterSpacing);
  }
}

// Exports
// This allows variables to be used with ./src/components/tokens/Typography/Typography.ts
:export {
  // Font Family
  @each $name, $value in $fontFamilies {
    @each $prop, $value in $value {
      @if type-of($value) == map {
        @each $option, $value in $value {
          fontFamilies-#{$name}-#{$prop}-#{$option}: $value;
        }
      } @else {
        fontFamilies-#{$name}-#{$prop}: $value;
      }
    }
  }
  // Reset Typography
  @each $prop, $value in $resetTypography {
    resetTypography-#{$prop}: $value;
  }
  // Typography
  @each $category, $value in $typography {
    @each $name, $value in $value {
      @each $prop, $value in $value {
        @if type-of($value) == map {
          @each $option, $value in $value {
            typography-#{$category}-#{$name}-#{$prop}-#{$option}: $value;
          }
        } @else {
          typography-#{$category}-#{$name}-#{$prop}: $value;
        }
      }
    }
  }
}
