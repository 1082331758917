$sourceColors: (
  smoke: #f5f5f5,
  silver: #c2c2c2,
  iron: #767f81,
  pencil: #CCCCCC,
  eclipse: #3d3d3d,
  roseTint: #fffaf9,
  rose: #f9efec,
  roseShade: #f6e7e2,
  tomato: #d73a15,
  tomatoTint: #e8593b,
  tomatoShade: #a92e14,
  beetTint: #81363b,
  beet: #511116,
  beetShade: #37060a,
  turquoiseTint: #d1fcff,
  turquoise: #8fdee3,
  turquoiseShade: #81b8bb,
  mintTint: #6ba6aa,
  mint: #437072,
  mintShade: #345455,
  blueberryTint: #5395cb,
  blueberry: #1775c2,
  blueberryShade: #0f4c7e,
  spinachTint: #7eaF5d,
  spinach: #408612,
  spinachShade: #3d631e,
  sienna: #b25b18,
  goldClay: #857351,
  timber: #405700,
  deepSea: #0b3c3d,
  midnight: #1a3352,
  violet: #321a52,
  // These should be cleaned up. Extraneous and too similar.
  tabasco: #A72E14,
  tabascoHover: #A92D10,
  potPourri: #F6E7E2,
  dawnPink: #f3eae5,
  dustyGray: #999999,
  //These are for the CCO Branded colors
  denim: #416490,
  arapawa: #2E4A69,
  malibu: #69A0E1,
  cornflower: #E6F1FF,
  aliceBlue: #F7FAFF,
  //These are for the CIO Branded colors
  cork: #3F2B1E,
  squirrel: #94856B,
  dijon: #EED681,
  sand: #FFF5C2,
  linen: #FCF9F3,
  ivory: #FFFDEB,
);

@function sourceColor($color) {
  @return map-get($sourceColors, $color);
}

$colors: (
  neutral: (
    white: (
      base: #ffffff,
    ),
    light: (
      tint: #ffffff,
      base: sourceColor(smoke),
      shade: sourceColor(pencil),
      shadeTwo: sourceColor(silver),
    ),
    dark: (
      tint: sourceColor(iron),
      base: sourceColor(eclipse),
      shade: #000000,
    ),
    black: (
      tint: sourceColor(dustyGray),
      base: #000000,
    ),
  ),
  primary: (
    light: (
      tint: sourceColor(roseTint),
      base: sourceColor(rose),
      shade: sourceColor(roseShade),
    ),
    mid: (
      tint: sourceColor(tomatoTint),
      base: sourceColor(tomato),
      shade: sourceColor(tomatoShade),
    ),
    dark: (
      tint: sourceColor(beetTint),
      base: sourceColor(beet),
      shade: sourceColor(beetShade),
    ),
  ),
  secondary: (
    mid: (
      tint: sourceColor(turquoiseTint),
      base: sourceColor(turquoise),
      shade: sourceColor(turquoiseShade),
    ),
    dark: (
      tint: sourceColor(mintTint),
      base: sourceColor(mint),
      shade: sourceColor(mintShade),
    ),
  ),
  marketing: (
    blue: (
      tint: sourceColor(blueberryTint),
      base: sourceColor(blueberry),
      shade: sourceColor(blueberryShade),
    ),
    green: (
      tint: sourceColor(spinachTint),
      base: sourceColor(spinach),
      shade: sourceColor(spinachShade),
    ),
    red: (
      buyNow: sourceColor(tabasco),
      buyNowHover: sourceColor(potPourri),
      error: sourceColor(tabasco),
      tabascoHover: sourceColor(tabascoHover),
    ),
    pink: (
      newsletterText: sourceColor(dawnPink),
    )
  ),
  editorial: (
    red: (
      base: sourceColor(beet),
    ),
    orange: (
      base: sourceColor(sienna),
    ),
    yellow: (
      base: sourceColor(goldClay),
    ),
    green: (
      base: sourceColor(timber),
    ),
    blueGreen: (
      base: sourceColor(deepSea),
    ),
    blue: (
      base: sourceColor(midnight),
    ),
    purple: (
      base: sourceColor(violet),
    ),
  ),
  brand: (
    cio: (
      primary: sourceColor(squirrel),
      secondary: sourceColor(cork),
      font: sourceColor(cork),
      link: sourceColor(dijon),
      linkHover: sourceColor(sand),
      background: sourceColor(linen),
      callout: sourceColor(ivory)
    ),
    cco: (
      primary: sourceColor(denim),
      secondary: sourceColor(arapawa),
      font: #000000,
      link: sourceColor(malibu),
      linkHover: sourceColor(cornflower),
      background: #ffffff,
      callout: sourceColor(aliceBlue),
    ),
  )
);

@function color($keys...) {
  $value: $colors;
  @each $key in $keys {
    $value: map-get($value, $key);
  }
  @if type-of($value) == map {
    @return map-get($value, base);
  } @else {
    @return $value;
  }
}

// Exports
// This allows variables to be used with ./src/components/tokens/Colors/Colors.ts
:export {
  // Source Color
  @each $color, $value in $sourceColors {
    sourceColors-#{$color}: $value;
  }
  // Color
  @each $category, $value in $colors {
    @each $hue, $value in $value {
      @each $variant, $value in $value {
        colors-#{$category}-#{$hue}-#{$variant}: $value;
      }
    }
  }
}
