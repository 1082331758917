/***
    The new CSS reset - version 1.7.3 (last updated 7.8.2022)
    GitHub page: https://github.com/elad2412/the-new-css-reset
***/

/*
    Remove all the styles of the "User-Agent-Stylesheet", except for the 'display' property
    - The "symbol *" part is to solve Firefox SVG sprite bug
*/
@use './_tokens/_colors.scss';
@use './_tokens/_typography.scss';

// TODO: added <del> here for older Mise 1.0 pages since reset styles were overwriting intended css
*:where(:not(html, iframe, canvas, img, svg, video, audio, del):not(svg
      *, symbol *)) {
  all: unset;
  display: revert;
}

/* Preferred box-sizing value */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Reapply the pointer cursor for anchor tags */
a,
button {
  cursor: revert;
}

/* Remove list styles (bullets/numbers) */
ol,
ul,
menu {
  list-style: none;
}

/* For images to not be able to exceed their container */
img {
  max-width: 100%;
}

/* removes spacing between cells in tables */
table {
  border-collapse: collapse;
}

/* Safari - solving issue when using user-select:none on the <body> text input doesn't working */
input,
textarea {
  -webkit-user-select: auto;
}

/* revert the 'white-space' property for textarea elements on Safari */
textarea {
  white-space: revert;
}

/* minimum style to allow to style meter element */
meter {
  -webkit-appearance: revert;
  appearance: revert;
}

/* reset default text opacity of input placeholder */
::placeholder {
  color: unset;
}

/*  fix the feature of 'hidden' attribute. display:revert; revert to element instead of attribute */
:where([hidden]) {
  display: none;
}

/*  revert for bug in Chromium browsers
    - fix for the content editable attribute will work properly.
    - webkit-user-select: auto; added for Safari in case of using user-select:none on wrapper element
*/
:where([contenteditable]:not([contenteditable='false'])) {
  -moz-user-modify: read-write;
  -webkit-user-modify: read-write;
  overflow-wrap: break-word;
  -webkit-line-break: after-white-space;
  -webkit-user-select: auto;
}

/* apply back the draggable feature - exist only in Chromium and Safari */
:where([draggable='true']) {
  -webkit-user-drag: element;
}

/* Custom */
* {
  font-style: revert;
  font-weight: revert;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @media (pointer: fine) {
    &:focus-visible {
      box-shadow: none;
      outline: currentColor dotted 2px;
      outline-offset: 2px;
    }
  }
}

html,
body,
#root,
#__next {
  @include typography.resetTypography();

  color: colors.color(neutral, dark);
  min-height: 100%;
}

// TODO: REMOVE: Temporary Espresso Fix
html {
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;

  &.has-overlay {
    overflow: hidden;
  }
}

/* Temporary homepage fix for input in global footer */

.form__group--input.form__email {
  background-color: white;
}
