.disclosure {
  button {
    cursor: pointer;
  }
}
.icon {
  transition: transform 0.4s ease-in-out;
}
[aria-expanded="true"] {
  .icon {
    transform: rotateX(180deg);
  }
}
.hidden {
  display: none;
}
